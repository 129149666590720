import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import SEO from "../../../../src/components/SEO/seo";
import TransitionLink from "../../../../src/components/TransitionLink/TransitionLink";
import * as React from 'react';
export default {
  Link,
  FontAwesomeIcon,
  faCaretUp,
  ExternalLink,
  SEO,
  TransitionLink,
  React
};