import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const ExternalLink = ({ href, text, wrap }) => (
  <a href={href} target="_blank" rel="noreferrer" className={`link${wrap ? ' wrap' : ''}`}>
    {text}{' '}
    <FontAwesomeIcon
      color="#59BEC0"
      size="xs"
      transform="shrink-2"
      icon={faExternalLinkAlt}
    />
  </a>
);

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ExternalLink;
