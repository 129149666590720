import React from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const TransitionLink = ({ to, className, children }) => (
  <AniLink paintDrip duration={1} hex="#77a6a7" to={to} className={`${className} link`}>
    {children}
  </AniLink>
);

TransitionLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TransitionLink.defaultProps = {
  className: '',
};

export default TransitionLink;
